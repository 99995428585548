import React from "react"

import colors from "../colors"

import { LandingLayout } from "../components/layouts"
import SEO from "../components/seo"

import PageWrapper from "../components/pageWrapper"
import TextBlock from "../components/textBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import PersonList from "../components/person/PersonList"
import Map from "../components/map"

import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
  LiveEventButton,
} from "../components/buttons"

const iconProps = {
  size: "60px",
  color: colors.darkPurple,
}

const LandingInPersonSeptember2021 = () => {
  return (
    <LandingLayout>
      <SEO
        title="Live Seminar: What You Need to Know About Long Term Care for You, Your Loved Ones, Your Company and Your Clients"
        ogDescription="Please join us for coffee, a light breakfast, networking and an educational workshop on Wednesday, September 22nd, 7:30 - 9:30 AM"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
          <h1>Long Term Care workshop on Wednesday, September 22nd</h1>
          <p>
            Please join us for breakfast, networking and an educational presentation on Wednesday, September 22nd, 7:30 – 9:30 AM.
          </p>
          <p>
            Corey Rieck, MBA, CLTC, will be presenting:
          </p>
          <h2>
            What You Need to Know About Long Term Care for You, Your Loved Ones, Your Company and Your Clients
          </h2>
          <p>
            Corey will provide you with answers to these important questions:
          </p>
          <ol>
            <li>What is Long Term Care?</li>
            <li>What is the Cost of Long Term Care?
              <ul>
                <li>The Impact on Caregivers</li>
                <li>The Financial Cost of Care</li>
              </ul>
            </li>
            <li>Who or What Pays for Long Term Care?</li>
          </ol>
          <p>
           <LiveEventButton />
          </p>
          </div>
          }
          textRight={
            <div>
              <img
                src="/images/AdobeStock_159276199.jpg"
                alt="What You Need to Know About Long Term Care"
              />
            </div>
          }
        />
        <SingleColumnBlock
          textPadded
          text={
          <div>
          <h3>
            Agenda
          </h3>
          <p>
            <b>7:30 - 8:00 AM</b> - Friendly conversation over coffee and breakfast<br />
            <b>8:00 - 8:45 AM</b> - Educational Presentation<br />
            <b>8:45 - 9:00 AM</b> - Q and A<br />
            <b>9:00 - 9:30 AM</b> - Closing discussion and sponsor comments<br />
          </p>
          <h3>
            Location
          </h3>
          <p>
            Serendipity Labs - Atlanta - Cumberland Vinings<br />
            3225 Cumberland Boulevard, Suite 100, Atlanta, GA 30339<br />
            RSVP by <strong>Monday, September 20th</strong>. Seating is limited to the first 20 people.
          </p>
          <p>
            Contact Corey to reserve your seat at <a href="mailto:corey@thelongtermcareplanninggroup.com">corey@thelongtermcareplanninggroup.com</a> or <a href="tel:6788145088">(678) 814-5088</a>.  Or <a href="https://www.eventbrite.com/e/atlanta-breakfast-and-educational-workshop-on-long-term-care-tickets-166840268735">click now to register online</a>.
          </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
          <h2>Request a meeting or phone call</h2>
          
          <p>
            If you can't make the event, click the button below to schedule your free, no-obligation LTC Consult with Corey Rieck of The Long Term Care Planning Group (a $99.95 value). Or, you can call <a href="tel:6788145088">(678) 814-5088</a> to schedule a meeting over the phone.
          </p>

          <p>
           <ScheduleVisitButton />
          </p>

          <h2>Or ... sign up for our free webinar</h2>
          <p>
          Sometimes the best way to learn more about a complex topic is to attend a short webinar to start with the basics. Even if you already have done long term care planning, you may still have unanswered questions about your coverage or plan. This webinar is about 20 minutes. Corey quickly introduces himself and then covers important details you need to know about long term care planning. You'll also receive a summary PDF overview for your review.
          </p>
          
          <div class="widget_wrapper"><div class="ewp_form_container ewp_widget_container" webinar_id="oaLCLjWqVbg9eimIr7VImQ==" ></div> <script type="text/javascript" src="https://ewpcdn.easywebinar.com/widget/js/ewp_widget.js?v=1.27.5" ></script></div>
          </div>
        }
      />
      <PageWrapper>
        <div
          style={{
            height: "1px",
            width: "100%",
            borderTop: "3px solid whitesmoke",
            display: "block",
            margin: "50px 0 50px 0",
          }}
        ></div>
        <PersonList shortnames={["corey-rieck"]} />
      </PageWrapper>
    </LandingLayout>
  )
}

export default LandingInPersonSeptember2021
